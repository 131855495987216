<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class=" d-table w-100" id="home" style="padding-top: 50px!important;">
      <div class="container">
        <div class="row mt-5 align-items-center">
          <div class="col-lg-6 col-md-7">
            <div class="title-heading">
              <h1 class="heading mb-3">
                <span class="text-primary">Cutill,</span>
                l'application de gestion de vos encaissements
              </h1>
              <p class="para-desc text-muted">
                Gagnez en temps et efficacité, gérez vos rendez-vous, vos produits et services, vos encaissements et exports comptables à partir d'un seul outil.
              </p>
              <div class="mt-4">
                <a href="https://apps.apple.com/us/app/cutill/id1548678897" target="_blank" class="btn btn-primary mt-2 mr-2"
                ><i class="mdi mdi-apple"></i> App Store</a
                >
                <a
                    href="https://play.google.com/store/apps/details?id=com.kmedini.cutill" target="_blank"
                    class="btn btn-outline-primary mt-2"
                ><i class="mdi mdi-google-play"></i> Play Store</a
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="text-md-right text-center ml-lg-4">
              <img src="images/cutill/caisse.png" class="img-fluid" alt="" width="350px" />
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-light">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Features Start -->
    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Les fonctionnalitées</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Installez <span class="text-primary font-weight-bold">Cutill</span> et profitez de l'ensemble de nos fonctionnalitées.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center align-items-center">
          <div class="col-lg-8 col-md-8">
            <div class="row mt-4 pt-2">
              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div
                      class="icon text-center rounded-circle text-primary mr-3 mt-2"
                  >
                    <monitor-icon
                        class="fea icon-ex-md text-primary"
                    ></monitor-icon>
                  </div>
                  <div class="media-body">
                    <h4 class="title">Disponible de partout</h4>
                    <p class="text-muted para mb-0">
                      Ayez toujours sur vous votre outils de travail. Encaissez et envoyez un reçu par email à vos clients.
                    </p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div
                      class="icon text-center rounded-circle text-primary mr-3 mt-2"
                  >
                    <feather-icon
                        class="fea icon-ex-md text-primary"
                    ></feather-icon>
                  </div>
                  <div class="media-body">
                    <h4 class="title">Créez votre catalogue</h4>
                    <p class="text-muted para mb-0">
                      Créez facilement vos produits et services afin d'encaisser en quelques cliques vos clients.
                    </p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div
                      class="icon text-center rounded-circle text-primary mr-3 mt-2"
                  >
                    <eye-icon class="fea icon-ex-md text-primary"></eye-icon>
                  </div>
                  <div class="media-body">
                    <h4 class="title">Suivis de votre activité</h4>
                    <p class="text-muted para mb-0">
                      Ne perdez plus le fil, votre dashboard vous permettra de visualiser votre activités rapidement.
                    </p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div
                      class="icon text-center rounded-circle text-primary mr-3 mt-2"
                  >
                    <user-check-icon
                        class="fea icon-ex-md text-primary"
                    ></user-check-icon>
                  </div>
                  <div class="media-body">
                    <h4 class="title">Export comptable</h4>
                    <p class="text-muted para mb-0">
                      Fini la paperasse comptable, vous allez pouvoir en 1 clique télécharger vos exports comptable.
                    </p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div
                      class="icon text-center rounded-circle text-primary mr-3 mt-2"
                  >
                    <smartphone-icon
                        class="fea icon-ex-md text-primary"
                    ></smartphone-icon>
                  </div>
                  <div class="media-body">
                    <h4 class="title">Ajoutez et affichez vos rendez-vous</h4>
                    <p class="text-muted para mb-0">
                      Fini l'agenda papier, ajoutez un rendez-vous en 30sec et accèdez à vos disponibilités en un clique.
                    </p>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->

          <div
              class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-right"
          >
            <carousel :interval="1000" >
              <slide><img src="images/cutill/dashboard.png" class="img-fluid" alt="" /></slide>
              <slide><img src="images/cutill/calendar.png" class="img-fluid" alt="" /></slide>
              <slide><img src="images/cutill/activities.png" class="img-fluid" alt="" /></slide>
            </carousel>

          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Price Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Abonnement unique et sans engagement 🚀</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Abonnez-vous dès maintenant et profitez
                <span class="text-primary font-weight-bold">du 1er mois offert</span>, sans engagement et sans conditions d'annulation. Restez libre d'arrêtez à tout moment et même de revenir plus tard..
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <Pricing :pricing-data="pricingData"/>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Price End -->

    <!-- Section End -->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
        href="javascript: void(0);"
        class="btn btn-icon btn-primary back-to-top"
        id="back-to-top"
        v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<script>
import {
  ArrowUpIcon,
  MonitorIcon,
  FeatherIcon,
  EyeIcon,
  UserCheckIcon,
  SmartphoneIcon,
  HeartIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import Pricing from "@/components/pricing2";
import Testimonial from "@/components/testimonial";


/**
 * Index application component
 */
export default {
  data() {
    return {
      testimonialData: [
        {
          id: 1,
          profile: "images/client/01.jpg",
          message:
            "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.",
          name: "Thomas Israel",
          designation: "C.E.O",
        },
        {
          id: 2,
          profile: "images/client/02.jpg",
          message:
            "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.",
          name: "Barbara McIntosh",
          designation: "M.D",
        },
        {
          id: 3,
          profile: "images/client/03.jpg",
          message:
            "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
          name: "Carl Oliver",
          designation: "P.A",
        },
        {
          id: 4,
          profile: "images/client/04.jpg",
          message:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
          name: "Christa Smith",
          designation: "Manager",
        },
        {
          id: 5,
          profile: "images/client/05.jpg",
          message:
            "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
          name: "Dean Tolle",
          designation: "Developer",
        },
        {
          id: 6,
          profile: "images/client/05.jpg",
          message:
            "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
          name: "Jill Webb",
          designation: "Designer",
        },
      ],
       pricingData: [
        {
          title: "Starter",
          price: 14.99,
          feature: [
            "Catalogue produits illimités",
            "Ventes illimitées",
            "Exports comptables illimités",
            "Calendrier de rendez-vous",
            "Dashboard de suivi"
          ],
          button: "Télécharger maintenant",
          isBest: false,
        }
      ],
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Pricing,
    Testimonial,
    ArrowUpIcon,
    Carousel,
    Slide,
    MonitorIcon,
    FeatherIcon,
    EyeIcon,
    UserCheckIcon,
    SmartphoneIcon,
    HeartIcon,
  },
};
</script>


